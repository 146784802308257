import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Container,
    Button,
    ListItem,
    Drawer,
    Divider,
    List,
    ListItemText,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useNavigate } from "react-router-dom";

const pages = [
    { displayName: "Hjem", url: "/" },
    { displayName: "Hytten", url: "/hytten" },
    { displayName: "Beliggenhet", url: "/beliggenhet" },
    { displayName: "Kontakt", url: "/kontakt" },
    { displayName: "Priser", url: "/priser" },
];

const ResponsiveAppBar = ({ toggleTheme }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const DrawerHeader = styled("div")(({ theme }) => ({
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: "space-between",
    }));

    return (
        <div>
            <AppBar
                position="fixed"
                elevation={0}
                sx={{
                    background: theme.palette.background.default,
                    color: theme.palette.text.primary,
                }}
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                        >
                            KRONBORG
                        </Typography>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            <IconButton
                                size="large"
                                onClick={() => setDrawerOpen(true)}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Box>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                            }}
                        >
                            KRONBORG
                        </Typography>
                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                            }}
                        >
                            {pages.map((page) => (
                                <Button
                                    key={page.displayName}
                                    onClick={() => navigate(page.url)}
                                    sx={{
                                        my: 2,
                                        display: "block",
                                        color: "inherit",
                                    }}
                                >
                                    {page.displayName}
                                </Button>
                            ))}
                        </Box>
                        <IconButton
                            sx={{
                                display: { xs: "none", md: "flex" },
                                mr: 2,
                                color: "inherit",
                            }}
                            onClick={toggleTheme}
                        >
                            {theme.palette.mode === "light" ? (
                                <DarkModeIcon />
                            ) : (
                                <LightModeIcon />
                            )}
                        </IconButton>
                    </Toolbar>
                </Container>
            </AppBar>
            <Drawer
                sx={{
                    width: 240,
                    flexShrink: 0,
                    "& .MuiDrawer-paper": {
                        width: 240,
                        boxSizing: "border-box",
                    },
                }}
                onClose={() => setDrawerOpen(false)}
                variant="temporary"
                anchor="left"
                open={drawerOpen}
            >
                <DrawerHeader>
                    <IconButton
                        sx={{
                            mr: 2,
                            color: "inherit",
                        }}
                        onClick={toggleTheme}
                    >
                        {theme.palette.mode === "light" ? (
                            <DarkModeIcon />
                        ) : (
                            <LightModeIcon />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        sx={{ color: "inherit" }}
                    >
                        {drawerOpen ? (
                            <ChevronLeftIcon />
                        ) : (
                            <ChevronRightIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {pages.map((page) => (
                        <ListItem
                            button
                            key={page.displayName}
                            onClick={() => {
                                navigate(page.url);
                                setDrawerOpen(false);
                            }}
                        >
                            <ListItemText primary={page.displayName} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </div>
    );
};
export default ResponsiveAppBar;
