import React from "react";
import {
    Typography,
    useTheme,
    Box,
    Grid,
    Card,
    CardContent,
    CardMedia,
} from "@mui/material";

const images = [
    { path: "static/forside.jpg", description: "Hytta sett nedenifra" },
    { path: "static/kronborg_vinter.jpg", description: "Winter wonderland" },
    {
        path: "static/inngang_bla.jpg",
        description:
            "Inngang til 'Blå' som den vestlige hytten heter, og skibod med tørkeskap",
    },
    {
        path: "static/parkering.jpg",
        description:
            "Parkeringen til den 'røde' hytta. Plass til flere biler foran begge hytter",
    },
    {
        path: "static/utsikt_SO2.jpg",
        description: "Innkjøringen til hytta, med port",
    },
    {
        path: "static/utsikt_tisleia.jpg",
        description: "Utsikt nedenfor veranda",
    },
    {
        path: "static/hytte_nord.jpg",
        description: "Hytta sett fra oversiden(bak)",
    },
    {
        path: "static/hostkveld_utsikt.jpg",
        description: "Utsikt en sen høstkveld",
    },
    {
        path: "static/kjokken_bla.jpg",
        description: "Kjøkkenet i den 'blå' hytta",
    },
    {
        path: "static/loype_skogshorn.jpg",
        description: "Vakre skiløyper ved skogshorn",
    },
    {
        path: "static/loyper_kjolen.jpg",
        description: "Fine løyper på Kjølen også ",
    },
    {
        path: "static/peiskos.jpg",
        description: "Peiskos etter en dag i løypa",
    },
    {
        path: "static/skogshorn.jpg",
        description: "Fjellet Skogshorn ligger ikke langt unna",
    },
    { path: "static/skogshorn2.jpg", description: "Skogshorn i sin majestet" },
    { path: "static/soverom_koye.jpg", description: "Soverom med én køyeseng" },
    {
        path: "static/soverom2enkeltsenger.jpg",
        description: "Soverom med to enkeltsenger",
    },
    {
        path: "static/soverom2koyer.jpg",
        description: "Soverom med en køyeseng og en bred enkeltseng",
    },
    {
        path: "static/spisestue_utsikt.jpg",
        description: "Utsikt fra spisestue i blå hytte",
    },
    {
        path: "static/spisestue_utsikt2.jpg",
        description: "Spisestue i blå hytte",
    },
    { path: "static/stue_bla.jpg", description: "Koselig stue i blå" },
    { path: "static/stue_peis.jpg", description: "Koselig stue i blå" },
    {
        path: "static/utsikt_storevann.jpg",
        description: "Flott utsikt fra verandaen i rød hytte",
    },
    {
        path: "static/utsikt_stuen.jpg",
        description: "Utsikt fra spisestue i rød hytte",
    },
    { path: "static/utsikt_SV.jpg", description: "Høstutsikt fra rød" },
    { path: "static/utsikt_vinter.jpg", description: "Vinterutsikt fra rød" },
    {
        path: "static/velutstyrt_kjokken.jpg",
        description: "Velutstyrt kjøkken i blå",
    },
];

export default function Cabin() {
    const theme = useTheme();
    return (
        <div
            style={{
                padding: theme.spacing(2),
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Typography variant="h1" sx={{ mb: 1 }}>
                Hytten
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: 600,
                    maxWidth: "100%",
                    mb: 2,
                }}
            >
                <Typography sx={{ mb: 2 }}>
                    Hytten har flott utsikt over Storevann, Golsfjellet og
                    Hemsedal. Den er sydvendt med solrik veranda og vei helt
                    frem. Dette er en dobbelthytte, det er den vestvendte hytten
                    som leies ut. Hytten er på 70m2, har 3 soverom med 8
                    sengeplasser, stue og spisestue i åpen løsning, kjøkken med
                    kjøl/frys, oppvaskmaskin, stekeovn, mikrobølgeovn og
                    kaffetrakter. Kabel-TV, peis og vedovn. Egen skibod med
                    tørkeskap. Hytten kan leies ut på åremål eller hele uker
                    eller helger.
                </Typography>
                <Grid container columns={{ xs: 1, sm: 2 }} spacing={{ xs: 2 }}>
                    {images.map((image, index) => (
                        <Grid item xs={2} sm={1} key={index}>
                            <Card
                                onClick={() => window.open(image.path)}
                                sx={{ cursor: "pointer" }}
                            >
                                <CardMedia
                                    component="img"
                                    height="194"
                                    image={image.path}
                                    alt={image.description}
                                />
                                <CardContent>
                                    <Typography
                                        variant="body2"
                                        color="text.secondary"
                                    >
                                        {image.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </div>
    );
}
