import React, { useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import Cabin from "./pages/Cabin";
import Location from "./pages/location/Location";
import Contact from "./pages/Contact";
import Prices from "./pages/Prices";

function App() {
    const [themeMode, setThemeMode] = useState(
        window.matchMedia("(prefers-color-scheme: dark)").matches
            ? "dark"
            : "light"
    );
    const theme = createTheme({
        palette: { mode: themeMode },
        typography: { h1: { fontSize: 48 }, h2: { fontSize: 32 } },
    });

    const toggleTheme = () => {
        setThemeMode(themeMode === "light" ? "dark" : "light");
    };
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
                <Layout toggleTheme={toggleTheme}>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/hytten" element={<Cabin />} />
                        <Route path="/beliggenhet" element={<Location />} />
                        <Route path="/kontakt" element={<Contact />} />
                        <Route path="/priser" element={<Prices />} />
                    </Routes>
                </Layout>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
