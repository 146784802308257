import React, { useState } from "react";
import { useTheme, Box, Typography, TextField, Button } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

export default function Contact() {
    const theme = useTheme();
    const [requestSent, setRequestSent] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: "",
            email: "",
            message: "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Required"),
            email: Yup.string().required("Required"),
            message: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            const sendEmail = httpsCallable(functions, "sendEmail");
            sendEmail(values)
                .then(() => {
                    setRequestSent(true);
                    formik.resetForm();
                })
                .catch((err) => console.log("Feil skjedde"));
        },
    });

    return (
        <div
            style={{
                padding: theme.spacing(2),
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: 600,
                    maxWidth: "100%",
                    mb: 2,
                }}
            >
                <Typography variant="h1">Vil du leie?</Typography>
                <Typography sx={{ mb: 2 }}>
                    For forespørsel om leie, send mail til{" "}
                    <a
                        href="mailto:kronborg.hemsedal@gmail.com"
                        style={{
                            color: "inherit",
                            fontWeight: "bold",
                        }}
                    >
                        kronborg.hemsedal@gmail.com
                    </a>{" "}
                    eller fyll ut feltene under.
                </Typography>
                {requestSent ? (
                    <>
                        <Typography sx={{ my: 2 }}>
                            Din forepørsel ble sent. Hvis du ikke mottar en
                            bekreftelse på epost innen 10 minutter kan du
                            benytte eposten over.
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => setRequestSent(false)}
                        >
                            Send en ny forespørsel
                        </Button>
                    </>
                ) : (
                    <>
                        <TextField
                            name="name"
                            required
                            fullWidth
                            label="Navn"
                            sx={{ mb: 1 }}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={Boolean(
                                formik.touched.name && formik.errors.name
                            )}
                        />
                        <TextField
                            name="email"
                            required
                            fullWidth
                            label="Din epost"
                            sx={{ mb: 1 }}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={Boolean(
                                formik.touched.email && formik.errors.email
                            )}
                        />
                        <TextField
                            id="message"
                            name="message"
                            required
                            fullWidth
                            label="Melding"
                            multiline
                            rows={5}
                            sx={{ mb: 1 }}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            error={Boolean(
                                formik.touched.message && formik.errors.message
                            )}
                        />

                        <Button
                            variant="contained"
                            onClick={formik.handleSubmit}
                        >
                            Send
                        </Button>
                    </>
                )}
            </Box>
        </div>
    );
}
