import React from "react";
import { Typography, useTheme, Box } from "@mui/material";

export default function Home() {
    const theme = useTheme();
    return (
        <div
            style={{
                padding: theme.spacing(2),
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Box maxWidth={"md"}>
                <img
                    src="/static/inngang_bla2.jpg"
                    style={{
                        color: "white",
                        marginBottom: theme.spacing(2),
                        width: "100%",
                    }}
                    alt=""
                />
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: 600,
                    maxWidth: "100%",
                    mb: 2,
                }}
            >
                <Typography
                    variant="h1"
                    textAlign={"center"}
                    sx={{
                        fontSize: 24,
                        mb: 1,
                        fontWeight: "bold",
                    }}
                >
                    Koselig hytte på Lykkja i Hemsedal med fantastisk utsikt
                </Typography>
                <Typography textAlign={"center"}>
                    Det er flotte skiløyper nær hytten, turstier om sommeren, og
                    20 minutter å kjøre til Hemsedal Skisenter og golfbaner.
                    Hytten er sydvendt med solrik veranda. Dobbelthytte 2 x
                    70m2. Hver hytte har 3 soverom med 8 sengeplasser.
                </Typography>
            </Box>
        </div>
    );
}
