import React from "react";
import {
    useTheme,
    Box,
    Typography,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableContainer,
    Paper,
} from "@mui/material";

const priceCategories = [
    {
        title: "Sommer",
        prices: [
            { title: "Vanlig uke", price: "7 500 kr" },
            { title: "Vanlig helg", price: "4 800 kr" },
        ],
    },
    {
        title: "Vinter",
        prices: [
            { title: "Vanlig uke", price: "9 000 kr" },
            { title: "Vanlig helg", price: "5 600 kr" },
            {
                title: "Juleferien (uke 52)",
                price: "10 000 kr",
            },
            {
                title: "Vinterferie uke 7",
                price: "10 000 kr",
            },
            {
                title: "Vinterferie uke 8",
                price: "12 000 kr",
            },
            {
                title: "Vinterferie uke 9",
                price: "10 000 kr",
            },
            {
                title: "Påsken Man-Man (8 dager)",
                price: "16 000 kr",
            },
            {
                title: "Påsken Fre-Ons (5 dager)",
                price: "8 500 kr",
            },
            {
                title: "Påsken Ons-Man (5 dager)",
                price: "10 500 kr",
            },
        ],
    },
    {
        title: "Tillegg",
        description:
            "Hyttevask kommer i tillegg, leie av sengetøy og håndklær kan avtales:",
        prices: [
            { title: "Hyttevask", price: "1 300 kr" },
            {
                title: "Sengetøy og håndklær (pr. sett)",
                price: "300 kr",
            },
            { title: "Ved (pr. sekk)", price: "100 kr" },
        ],
    },
];

export default function Prices() {
    const theme = useTheme();

    return (
        <div
            style={{
                padding: theme.spacing(2),
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: 600,
                    maxWidth: "100%",
                    mb: 2,
                }}
            >
                <Typography variant="h1">Priser</Typography>
                <Typography sx={{ mb: 2 }}>
                    Leiepris vil normalt inkludere strøm, men strøm-tillegg kan
                    forekomme i perioder med høye strømpriser. Andre lengder på
                    opphold kan avtales.
                </Typography>

                {priceCategories.map((category) => (
                    <Box key={category.title} sx={{ width: 1, mb: 4 }}>
                        <Typography
                            variant="h2"
                            sx={{ mb: category.description ? 0 : 1 }}
                        >
                            {category.title}
                        </Typography>
                        <Typography sx={{ mb: category.description ? 1 : 0 }}>
                            {category.description}
                        </Typography>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableBody>
                                    {category.prices.map((period) => (
                                        <TableRow key={period.title}>
                                            <TableCell>
                                                {period.title}
                                            </TableCell>
                                            <TableCell align="right">
                                                {period.price}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                ))}
            </Box>
        </div>
    );
}
