import Navbar from "./Navbar";
import { styled } from "@mui/material";

export default function Layout({ children, toggleTheme }) {
    const HeaderBox = styled("div")(({ theme }) => ({
        display: "flex",
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));
    return (
        <div>
            <Navbar toggleTheme={toggleTheme} />
            <HeaderBox />
            {children}
            <div
                style={{
                    height: 100,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h4>© Kronborg {new Date().getFullYear()}</h4>
            </div>
        </div>
    );
}
