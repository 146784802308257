import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { useTheme, Box, Typography } from "@mui/material";
import { blueIcon, greenIcon, goldIcon } from "./markers";

export default function Location() {
    const theme = useTheme();

    const markers = [
        { markerIcon: blueIcon, position: [60.8856, 8.8131] },
        {
            markerIcon: greenIcon,
            position: [60.849905632742946, 8.615414245202174],
        },
        {
            markerIcon: goldIcon,
            position: [60.8632, 8.552],
        },
    ];
    return (
        <div
            style={{
                padding: theme.spacing(2),
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    width: 600,
                    maxWidth: "100%",
                    mb: 2,
                }}
            >
                <Typography variant="h1" sx={{ mb: 1 }}>
                    Beliggenhet
                </Typography>
                <Typography sx={{ mb: 1 }}>
                    Hytten ligger i Rustberggardlie 36 på Lykkja i Hemsedal. Fra
                    Gol, kjør RV 52 retning Hemsedal, ca 25 km til Ulsåk. Ta til
                    høyre til Lykkja. Etter 16 km passeres blått skilt ‘Lykkja’.
                    Fortsett 200 m, og ta til venstre ved skilt
                    ‘Rustberggardlia’. Hold til venstre etter ca 100 m, fortsett
                    ca 100 m til og hytta ligger godt synlig opp til høyre.
                </Typography>
                <MapContainer
                    center={[60.88576, 8.81344]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{
                        height: 400,
                        width: 600,
                        maxWidth: "100%",
                    }}
                >
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {markers.map((marker) => (
                        <Marker
                            icon={marker.markerIcon}
                            position={marker.position}
                            key={marker.position}
                        />
                    ))}
                </MapContainer>
                <Typography sx={{ mt: 1 }}>
                    Både hytten(blå), Hemsedal sentrum(gul) og Ulsåk/avkjøring
                    til lykkja(grønn) er markert på kartet
                </Typography>
            </Box>
        </div>
    );
}
