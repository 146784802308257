import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
    apiKey: "AIzaSyCL6B55ICmmRWbZlCXI-pFfZwr43CL77bM",
    authDomain: "hytte-hemsedal.firebaseapp.com",
    projectId: "hytte-hemsedal",
    storageBucket: "hytte-hemsedal.appspot.com",
    messagingSenderId: "74450725461",
    appId: "1:74450725461:web:3d3ff34d613c384cfe82dc",
};

// Init firebase app
const app = initializeApp(firebaseConfig);

// Init firestore service
const functions = getFunctions(app);

const firestore = getFirestore();
export { firestore, functions };
